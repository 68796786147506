// Base colors
$black: #000000;
$white: #ffffff;
$primaryDark: rgba($black, 0.7);
$bgDark: #0b0b0f;
$primaryGray: #6e7898;
$lightGray: rgba($primaryGray, 0.25);
$primaryBlue: #005dff;
$primaryRed: #da223d;
$primaryYellow: #ffab00;

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap");

// Mixins
@mixin code {
  font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas,
    Courier New, monospace;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin code-block {
  @include code;
  font-size: 14px;
  background: $black;
  color: $white;
  border-radius: 4px;
  display: inline;
  line-height: 1.66667;
  overflow-x: auto;
  white-space: pre-wrap;
  padding: 12px;
  transform: translateY(0px);
  width: 320px;

  @media (hover: hover) {
    &:hover {
      @include shadow;
      border: 1px solid $primaryBlue;
    }
  }
}

@mixin transition {
  transition: all 0.25s ease 0s, font-size 0s, font-weight 0s;
}

@mixin shadow {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.125);
}

@mixin button {
  background: $black;
  color: $white;
  padding: 16px 30px 16px;
  outline: none;
  border-radius: 4px;
  border: 1px solid $black;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    box-shadow: $primaryBlue 0px 0px 0px 1px;
    border-color: $primaryBlue;
  }
}

// Google Icons
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(https://fonts.gstatic.com/s/materialicons/v21/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2)
      format("woff2");
}

.material-icons {
  font-family: "Material Icons";
}
