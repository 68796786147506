@import "./style/base.scss";
@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  image-rendering: -moz-crisp-edges;

  font-family: "Inter", "Inter var", -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji,
    Segoe UI Symbol;

  @include transition;
}

html,
body {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

img {
  image-rendering: auto;
}

.no-scroll {
  overflow: hidden;
  position: fixed;
}

input,
button,
textarea {
  font-size: inherit;
  font-family: inherit;
  -webkit-appearance: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.page {
  margin-top: 120px;
  margin-bottom: 120px;
  min-height: 100vh;
}

.container {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  color: $primaryBlue;
  text-decoration: none;
  font-weight: 500;
}

/*** UTILITIES ***/
.m-0 {
  margin: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.dialog-enter {
  opacity: 0;
}

.dialog-enter-active {
  animation-name: fadeIn;
  animation-duration: 300ms;
  opacity: 1;
  transition: opacity 300ms;
}

.dialog-exit {
  opacity: 1;
}

.dialog-exit-active {
  animation-name: fadeOut;
  animation-duration: 300ms;
  opacity: 0.01;
  transition: opacity 300ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(5px);
  }
}

@media (max-width: 420px) {
  .feeder-modal {
    display: none;
  }
}
