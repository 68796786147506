@import "../style/base.scss";

.navbar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  padding: 0px 12px;
  border-bottom: 1px solid $lightGray;
  height: 92px;
  width: 100%;
  background: $white;

  .logo {
    font-weight: 600;
    color: $primaryGray;
    letter-spacing: 2px;

    &:hover {
      color: $black;
    }
  }
}

.navbar-left {
  padding-left: 24px;

  .navbar-item {
    margin-left: 12px;
    margin-right: 32px;

    &:first-of-type {
      margin-left: 0px;
    }
  }
}

.navbar-item {
  cursor: pointer;
  font-weight: 500;
}

.navbar-right {
  padding-left: 32px;
  padding-right: 24px;
  border-left: 1px solid $lightGray;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .nav-active {
    &:not(.main-btn) {
      color: $black !important;
    }
  }

  .navbar-item {
    margin-right: 48px;

    &:not(.main-btn) {
      color: $primaryGray;

      &:hover {
        color: $black;
      }
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.navbar-profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: rgba($primaryBlue, 0.05);
  color: $primaryBlue !important;
  font-weight: 500;
}

@media (max-width: 640px) {
  .unauthed-large {
    display: none;
  }

  .dropdown {
    .unauthed-menu {
      display: block;

      svg {
        margin-right: 0px;
      }
    }
  }
}
