@import "../style/base.scss";

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .unauthed-ul {
    top: 32px;
  }

  .unauthed-ul,
  .authed-ul {
    svg {
      fill: $primaryBlue;
      background: rgba($primaryBlue, 0.05);
      border-radius: 50%;
      padding: 4px;
    }
  }

  .authed-ul {
    top: 52px;

    .logout-icon {
      transform: rotate(180deg);
    }
  }

  ul {
    list-style-type: none;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    right: 0px;
    width: 200px;
    background-color: white;
    font-weight: 500;
    position: absolute;

    @include shadow;
    z-index: 1;
  }

  .nav-user {
    color: $primaryGray;
    font-size: 14px;
    user-select: none;
    cursor: default !important;

    &:hover {
      padding-left: 16px;
      background-color: $white;
      cursor: inherit;
    }
  }

  li a {
    width: 100%;
  }
  li a,
  li {
    color: $black;
    text-decoration: none;
  }

  svg {
    margin-right: 8px;

    title,
    desc {
      display: none;
    }
  }

  li {
    padding: 16px;
    display: flex;
    align-items: center;
  }

  li:hover {
    padding-left: 20px;
    background-color: rgba($lightGray, 0.05);
    cursor: pointer;
  }
}

.unauthed-menu {
  display: none;
}
