@import "../style/base.scss";

.home-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  text-align: center;
}

.home-page-2 {
  margin-bottom: 80px;
}

.home-producthunt {
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}

.home-page-img {
  max-width: 720px;
  margin-bottom: 32px;

  img {
    animation: float 3s ease-in-out infinite;
    border: 1px solid $primaryBlue;
    border-radius: 4px;
    width: 100%;
    height: auto;
    @include shadow;
  }
}

@media (max-height: 640px) {
  .home-page-img {
    max-width: 540px;
  }
}

.home-header {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 32px;
}

.home-description {
  color: $primaryGray;
}

.github-badges {
  margin-top: 16px;
  img {
    margin: 8px;
  }
}

.home-repo-link {
  margin-top: 32px;
  color: $primaryGray;
  padding: 12px;
  border-radius: 4px;
  @include code;
  font-weight: 500;
  font-size: 14px;
  max-width: 90%;

  &:hover {
    background: rgba($primaryGray, 0.05);
    color: $black;
  }
}

.home-code {
  cursor: pointer;
  @include code-block;
  @include shadow;
  animation: float 3s ease-in-out infinite;
  max-width: 90%;
}

.author {
  font-size: 12px;
  margin-top: 40px;
  color: $primaryGray;
  font-weight: 500;

  a {
    color: $black;
    &:hover {
      color: $primaryBlue;
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
