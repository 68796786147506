@import "../style/base.scss";

.auth-form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form,
  .form-header,
  .form-description {
    width: 100%;
    max-width: 480px;
  }
}

.auth-register {
  justify-content: flex-start;
  margin-top: 120px;
}

.form-header {
  font-size: 20px;
  font-weight: 500;
}

.form-description {
  margin-bottom: 12px;
  margin-top: 8px;
  font-weight: 500;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-label {
  color: $primaryGray;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}

.input-label-error {
  color: $primaryRed;
}

.input {
  padding: 12px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid rgba($lightGray, 0.15);
  box-shadow: none;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    box-shadow: $primaryBlue 0px 0px 0px 1px;
    border-color: $primaryBlue;
  }

  &::placeholder {
    color: rgba($primaryGray, 0.5);
    font-weight: 500;
  }
}

.input-disabled {
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    border: 1px solid rgba($lightGray, 0.15);
  }
}

.input-button {
  @include button;
  width: 100%;
  margin-top: 8px;
}

.input-button-loader {
  display: inline-block;
  border: (16/120) * 16px solid $lightGray;
  border-top: (16/120) * 16px solid $white;
  border-radius: 50%;
  background: transparent;
  width: 16px;
  height: 16px;
  animation: spin 0.5s linear infinite;
}

.loader-dark {
  border: (16/120) * 18px solid $lightGray;
  border-top: (16/120) * 18px solid $black;
  width: 18px;
  height: 18px;
  margin: 0 auto;
}

.input-error {
  color: $primaryRed;
  margin-top: -8px;
  margin-bottom: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
