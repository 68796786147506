@import "../style/base.scss";

.admin-stats {
  * {
    @include code;
    @include hide-scrollbar;
  }

  pre {
    background: #00346e !important;
    padding: 32px;
    border-radius: 8px;
    font-size: 12px;

    .__json-key__ {
      color: $white !important;
    }
  }
}
