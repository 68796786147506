@import "../style/base.scss";

.create-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  // background: rgba($black, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: scroll;
}

.create-modal-container {
  position: fixed;
  width: 92.5%;
  max-width: 712px;
  border-radius: 4px;
  padding: 48px;
  max-height: 90%;
  overflow-y: scroll;
  background-color: $white;
  -ms-overflow-style: none;
  @include shadow;

  &::-webkit-scrollbar {
    display: none;
  }
}

.text-area-input {
  resize: vertical;
  max-width: 100%;
  min-height: 45.5px;
}

.create-modal-first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba($lightGray, 0.1);

  .create-modal-close {
    cursor: pointer;
    font-size: 12px;
    color: $primaryGray;

    &:hover {
      color: $primaryRed;
    }
  }
}

@media (max-width: 600px) {
  .create-modal-container {
    padding: 36px;
  }
}
