@import "../style/base.scss";

.project-cards-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  margin-bottom: 96px;
}

.project-card {
  border: 1px solid $lightGray;
  display: flex;
  width: 320px;
  margin: 10px;
  flex-direction: column;
  padding: 32px;
  border-radius: 4px;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    border-color: $primaryBlue;
    @include shadow;
  }
}

.project-first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.project-name {
  font-size: 24px;
  font-weight: 500;
  margin-right: 12px;
  word-wrap: break-word;
}

.project-card-dots {
  transform: rotate(90deg);
  svg {
    margin-left: 8px;
  }
}

.project-id,
.project-description {
  font-size: 14px;
}

.project-id {
  margin-bottom: 12px;
  color: $primaryBlue;
  width: fit-content;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.no-copy {
  color: $primaryGray;

  &:hover {
    text-decoration: none;
  }
}

.project-description {
  color: $primaryGray;
  height: 40px;
  overflow-y: hidden;
  margin-bottom: 12px;
}

.project-lozenges {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -6px;
}

.lozenge {
  padding: 5px 6px;
  margin: 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  user-select: none;
}

.lozenge-blue {
  background: rgba($primaryBlue, 0.05);
  color: $primaryBlue;
}

.lozenge-red {
  background: rgba($primaryRed, 0.05);
  color: $primaryRed;
}

.lozenge-yellow {
  background: rgba($primaryYellow, 0.05);
  color: $primaryYellow;
}

.lozenge-gray {
  background: rgba($primaryGray, 0.05);
  color: $primaryGray;
}

.lozenge-black {
  background: rgba($black, 0.05);
  color: $black;
}

.project-card-owner {
  margin-top: 0px;
  display: inline-block;
}

@media (max-width: 1280px) {
  .project-card {
    width: 47%;
  }
}

@media (max-width: 830px) {
  .project-cards-grid {
    flex-direction: column;
    margin: 0px 0px 120px 0px;
  }
  .project-card {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 640px) {
  .table-filter {
    display: none;
  }
}
