@import "../style/base.scss";

.dashboard-header {
  font-size: 32px;
  font-weight: 500;
  word-wrap: break-word;
}

.dashboard-description {
  color: $primaryGray;
  margin: 12px 0px 18px 0px;
  font-weight: 500;
  line-height: 1.4;

  a {
    color: $primaryBlue;
  }
}

.main-btn {
  @include button;
}

.dashboard-tabs {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin-left: -20px;
  margin-bottom: 24px;
  margin-top: 12px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .dashboard-tab {
    margin-left: 20px;
    color: $primaryGray;
    font-weight: 500;
    padding: 10px 0px 8px;
    cursor: pointer;
  }

  .tab-active {
    color: $black;
    cursor: pointer;
    border-bottom: 2px solid $black;
  }

  .dashboard-tab-disabled {
    cursor: not-allowed;
  }
}
